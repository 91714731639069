export interface WSResponseDto<T> {
  type: WSResponseType;
  data: T;
  status: string;
}

export enum WSResponseType {
  ONLINE_PLAYERS = 'online_players',
  GAME_INVITES = 'game_invites',
  NOTIFICATIONS = 'notifications',
}
