import { Component, Input } from '@angular/core';
import { PlayerGame } from '../../models/player-game/PlayerGame';
import { PlayerGamePlayer } from '../../models/player/Player';
import { InviteStatus } from '../../../constants/InviteStatus';
import { ProgressBarTypes } from '../../../constants/ProgressTypes';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { CommonModule } from '@angular/common';
import { GameWsService } from '../../../services/web-sockets/game-ws.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { WSResponseDto } from '../../models/web-sockets/WebsocketResponseDto';
import { PlayerGameDto } from '../../models/player-game/PlayerGameDto';
import { SignalsService } from '../../../services/signals.service';
import { SidenavigationTypes } from '../../../constants/SidenavigationTypes';

@Component({
  selector: 'app-score-board',
  standalone: true,
  imports: [ProgressBarComponent, CommonModule],
  templateUrl: './score-board.component.html',
  styleUrl: './score-board.component.scss'
})
export class ScoreBoardComponent {
  @Input() game: PlayerGame;
  gameProgress = ProgressBarTypes.GAME_PROGRESS;
  inviteStatus = InviteStatus;

  trackByScore = (index: number, gamePlayer: PlayerGamePlayer) => gamePlayer ? gamePlayer.score : undefined;

  constructor(
    private gameWS: GameWsService, 
    private activatedRoute: ActivatedRoute,
    private signalService: SignalsService
  ) {
    this.activatedRoute.queryParamMap.subscribe((queryParams: ParamMap) => {
      const gameId = queryParams.get('gameId');
      if (gameId) {
        this.gameWS.connectToPlayerGameWS(+gameId);
        this.gameWS.playerGame.subscribe((response: WSResponseDto<PlayerGameDto>) => {
          this.game = new PlayerGame(response.data);
          this.signalService.gameDetail.set(this.game);
        });
      }
    });
  }
}
