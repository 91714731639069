@switch (progressBarType) {
  @case (progressBarTypes.LOADING) {
    <p>
      <ngb-progressbar [type]="percentage < 100 ? 'warning': 'success'" [value]="percentage">{{ percentage }}% of problems loaded</ngb-progressbar>
    </p>
  }
  @case (progressBarTypes.GAME_PROGRESS) {
    <div class='line-container mt-5'>
      <div class='progress-line'>
        <div class='progress' [ngStyle]="{'width': percentage + '%'}"></div>
        <div class="status" style="left: calc(0% - 15px)">
          <div class="dot"></div>
          <span style="color: var(--text-color-primary);">{{ player.user.firstName }} (XP: {{player.experience}})</span>
        </div>
        <div class="status" style="left: calc(100% - 15px)" *ngIf="percentage < 100">
          <span class="material-symbols-outlined final">
            trophy
            </span>
        </div>
        <div class="current-status text-center" [ngStyle]="{'left': 'calc(100% *' + percentage / 100 +' - 30px)'}">
          <div>
            <span class="mt-3">{{score | formatNumber : null}}</span>
          </div>
          <app-profile-container [profilePhotoUrl]="player.user?.profilePicture" [profileSection]="'scoreboard'"></app-profile-container>
        </div>
      </div>
    </div>
  }
}
