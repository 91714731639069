import { Injectable } from '@angular/core';
import { Subject, filter, map } from 'rxjs';
import { PlayerDto } from '../../shared/models/player/PlayerDto';
import { NotificationsDto } from '../../shared/models/notifications/NotificationsDto';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth.service';
import { WSResponseType } from '../../shared/models/web-sockets/WebsocketResponseDto';

@Injectable({
  providedIn: 'root'
})
export class GameWsService {
  private readonly playerRoomName: string = 'player';
  private readonly playerGameRoomName: string = 'player-game';
  private readonly wsUrl: string = environment.socketServer;

  playerGame: Subject<any>;
  onlinePlayers: Subject<PlayerDto[]>;
  gameInvitesNotifications: Subject<NotificationsDto[]>;
  notications: Subject<any>;
  
  constructor(private authService: AuthService) { }

  connectToPlayerGameWS(gameId: number): void {
    const wsUrlConnect = `${this.wsUrl}/${this.playerGameRoomName}/${gameId}/?auth=${this.authService.tokenvalue?.access}`
    this.playerGame = webSocket(wsUrlConnect);
  }

  connectToPlayerWS(): void {
    const playerResponse = webSocket(`${this.wsUrl}/${this.playerRoomName}/?auth=${this.authService.tokenvalue?.access}`);
    this.onlinePlayers = <Subject<PlayerDto[]>> playerResponse.pipe(
      filter((response: any) => response.type === WSResponseType.ONLINE_PLAYERS),
      map((data: any) => data.data ?? [])
    );
    this.gameInvitesNotifications = <Subject<NotificationsDto[]>> playerResponse.pipe(
      filter((response: any) => response.type === WSResponseType.GAME_INVITES),
      map((data: any) => data.data)
    );
    this.notications = <Subject<NotificationsDto[]>>  playerResponse.pipe(
      filter((response: any) => response.type === WSResponseType.NOTIFICATIONS),
      map((data: any) => data.data)
    );
  }
}
