import { Component, Input } from '@angular/core';
import { ProgressBarTypes } from '../../../constants/ProgressTypes';
import { Player } from '../../models/player/Player';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormatNumberPipe } from '../../pipes/format-number.pipe';
import { ProfileContainerComponent } from '../profile-container/profile-container.component';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [NgbProgressbarModule, CommonModule, FormatNumberPipe, ProfileContainerComponent],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {
  @Input() progressBarType: ProgressBarTypes = ProgressBarTypes.LOADING;
  @Input() percentage: number = 0;
  @Input() player: Player;
  @Input() score: number = 0;

  progressBarTypes = ProgressBarTypes;
}
