<div class="mt-2">
  <h5 class="card-title mb-0">Player score board</h5>
  <div class="card-body mt-2 score-board-container">
    @for (gamePlayer of game.gamePlayers; track trackByScore) {
      @if (gamePlayer.inviteStatus === inviteStatus.ACCEPTED) {
        <div class="d-flex w-100 justify-content-center align-items-center">
          <div style="width: 90%;">
            <app-progress-bar 
              [percentage]="gamePlayer.scorePercentage" 
              [progressBarType]="gameProgress" 
              [player]="gamePlayer.player"
              [score]="gamePlayer.score">
            </app-progress-bar>
          </div>

          <div class="d-flex align-items-center justify-content-center elo-tracker-container p-2" [ngStyle]="{'background-color': gamePlayer.eloDelta >= 0 ? 'var(--content-background-accent)' : '#b68a87'}">
            <span style="font-size: 15px;" [ngStyle]="{'color': gamePlayer.eloDelta >= 0 ? 'var(--text-color-light)' : '#d93025'}">{{gamePlayer.eloDelta}}</span>
          </div>
        </div>
      }
    }
  </div>
</div>
